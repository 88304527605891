<nav class="main-nav" #mainNav (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
  <header class="main-nav__header">
    <div class="main-nav__header__logo">
      <img class="logo" src="assets/img/logo.svg">
    </div>
    <div class="main-nav__header__title">
      {{ 'hydroponics.navigation.pageTitle' | translate }}
    </div>
  </header>
  <div class="main-nav__body">
    <div class="main-nav__menu">
      {{ 'hydroponics.navigation.menu' | translate }}
    </div>
    <div class="main-nav__items">
      <ng-container *ngFor="let menuItem of menuModel; let i = index">
        <div class="main-nav__item">
          <span class="{{menuItem.icon}}"></span>
          <a *ngIf="menuItem.routerLink" routerLink="{{menuItem.routerLink}}" routerLinkActive="main-nav__item__active"
             [routerLinkActiveOptions]="{exact: menuItem.routerLink!=WORKSHEET}">
            {{ menuItem.label | translate }}
          </a>
          <a *ngIf="!menuItem.routerLink" routerLink=""
             (click)="menuItem.command()">{{ menuItem.label | translate }}</a>
        </div>
      </ng-container>
    </div>
  </div>
</nav>

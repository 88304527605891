<div *ngIf="visible" class="card flex flex-column">
  <div *ngIf="isDateFilter" class="flex flex-row align-items-center mb-3">
    <span class="w-6rem">{{'hydroponics.chartFilter.dateRange' | translate}}: </span>
    <p-calendar [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="true" inputId="range" [showTime]="true" class="m-0 w-20rem d-grid"
      (onClickOutside)="emitDateChange()">
    </p-calendar>
  </div>

  <div *ngIf="isLocationFilter" class="flex flex-row align-items-center mb-3">
    <span class="w-6rem">{{'hydroponics.chartFilter.device' | translate}}:</span>
    <div class="flex align-items-center">
      <p-button [icon]="selectedNodes && selectedNodes.length>0 ? 'pi pi-filter-fill':'pi pi-filter'"
                (onClick)="showLocationFilterDialog()"
                class="mr-1">
      </p-button>
      <ng-container *ngIf="isMultiple && selectedNodes">
          <label>{{selectedNodesLabels}}</label>
      </ng-container>
      <ng-container *ngIf="!isMultiple && selectedNode">
        <label>{{selectedNode.label}}</label>
      </ng-container>
    </div>
  </div>

  <div *ngIf="isWorksheetFilter" class="flex flex-row align-items-center mb-3">
    <span class="w-6rem">{{'hydroponics.chartFilter.worksheetOrEvent' | translate}}: </span>
    <p-dropdown
      [options]="filterTypes"
      [(ngModel)]="filterType"
      (onChange)="emitWorksheetTypeChange()"
      optionLabel="label"
      placeholder="Select"></p-dropdown>
  </div>

  <div *ngIf="isAttributeFilter" class="flex flex-row align-items-center mb-3">
    <span class="w-6rem">{{'hydroponics.chartFilter.attributeNames' | translate}}:</span>
    <p-selectButton
      [options]="attributeNames"
      [(ngModel)]="selectedAttributeNames"
      [multiple]="true"
      (onChange)="emitAttributeChange()"
      optionLabel="label"
      optionValue="value">
    </p-selectButton>
  </div>

  <div *ngIf="isFrequencyFilter" class="flex flex-row align-items-center mb-3">
    <span class="w-6rem">{{'hydroponics.chartFilter.frequencies' | translate}}:</span>
    <p-multiSelect
      class="multiselect"
      appendTo="body"
      [options]="frequenciesList"
      [(ngModel)]="pickedFrequencies"
      (onChange)="frequenceSelectionChanged()"
      (onClear)="frequenceSelectionCleard()"
      placeholder="{{'hydroponics.button.choose' | translate}}"
      [showClear]="true">
    </p-multiSelect>
  </div>
</div>

<p-dialog modal="true" [(visible)]="dialogVisible" (onHide)="hideLocationFilterDialog()">
  <div class="mb-3">
    <app-location-tree [extendedTree]="false"
                       [deviceTypes]="deviceTypes"
                       [isMultiple]="isMultiple"
                       [deviceId]="this.deviceIds"
                       [locationId]="this.locationIds"
                       [isOnlyDeviceSelectable]="isOnlyDeviceSelectable"
                       (onLocationChange)="locationTreeLocationChanged($event)">
    </app-location-tree>
  </div>
  <div class="float-right">
    <p-button (onClick)="emitLocationChange()" label="{{'hydroponics.button.choose' | translate}}"></p-button>
  </div>
</p-dialog>

<p-dialog header="{{ 'hydroponics.alert.error_message' | translate }}"
          (onHide)="actionOnClose()"
          [(visible)]="visible"
          [style]="{width: '85vw'}"
          [maximizable]="true"
          [blockScroll]="true"
          [modal]="true">
  <div class="card">
    <div class="button-container">
      <p-button label="{{ 'hydroponics.alert.alertTable.selectAllButton' | translate }}"
                icon="pi pi-check"
                (onClick)="selectAllCheckboxes()">
      </p-button>
      <p-button label="{{ 'hydroponics.alert.alertTable.closeButton' | translate }}"
                icon="pi pi-times"
                (onClick)="closeAlert()">
      </p-button>
    </div>
    <p-table
      [paginator]="true"
      paginatorDropdownAppendTo="body"
      [lazy]="true"
      [rows]="rows"
      [first]="first"
      [totalRecords]="totalRecords"
      [rowsPerPageOptions]="pageSizeOptions"
      (onPage)="pageChanged($event)"
      [value]="alerts"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'hydroponics.alert.alertTable.time' | translate }}</th>
          <th *ngIf="!deviceId; else th_content " >
            <p-dropdown
              [options]="filterTypes"
              [(ngModel)]="filterType"
              (onChange)="onChangeDevice()"
              (onClear)="onDeviceCleared()"
              [showClear]="true"
              optionLabel="label"
              placeholder="Device name"></p-dropdown>
          </th>
          <ng-template #th_content><th>Device name</th></ng-template>
          <th>Description</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-alertNotification>
        <tr>
          <td>{{ alertNotification.alert.creationDate | date:'Y-MM-dd HH:mm' }}</td>
          <td>{{ alertNotification.alert.deviceName }}</td>
          <td>{{ alertNotification.alert.description }}</td>
          <td>
            <p-checkbox [binary]="true" [(ngModel)]="alertNotification.selected"
                        (onChange)="onCheckboxChange()"></p-checkbox>
          </td>
          <!--<td>
            <p-button class="hide-alert" icon="pi pi-times"
                      [disabled]="isLoading"
                      (onClick)="closeAlert(alertNotification.id)">
            </p-button>
          </td>-->
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>

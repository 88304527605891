/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetAlertsForImpedanceSensorByDeviceResponse } from '../models/get-alerts-for-impedance-sensor-by-device-response';
import { GetAlertsForNaSensorByDeviceResponse } from '../models/get-alerts-for-na-sensor-by-device-response';
import { HasRecentAlertsForNaSensorByDeviceResponse } from '../models/has-recent-alerts-for-na-sensor-by-device-response';
import { PageAlertNotificationDto } from '../models/page-alert-notification-dto';
import { Pageable } from '../models/pageable';

@Injectable({
  providedIn: 'root',
})
export class AlertManagementImplService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation closeAlert
   */
  static readonly CloseAlertPath = '/alerts/close';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `closeAlert()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  closeAlert$Response(params: {
    context?: HttpContext
    body: number
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AlertManagementImplService.CloseAlertPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `closeAlert$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  closeAlert(params: {
    context?: HttpContext
    body: number
  }
): Observable<void> {

    return this.closeAlert$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation closeAlerts
   */
  static readonly CloseAlertsPath = '/alerts/closeAlerts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `closeAlerts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  closeAlerts$Response(params: {
    context?: HttpContext
    body: Array<number>
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AlertManagementImplService.CloseAlertsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `closeAlerts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  closeAlerts(params: {
    context?: HttpContext
    body: Array<number>
  }
): Observable<void> {

    return this.closeAlerts$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation hideAlert
   */
  static readonly HideAlertPath = '/alerts/hide';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hideAlert()` instead.
   *
   * This method doesn't expect any request body.
   */
  hideAlert$Response(params: {
    alertNotificationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AlertManagementImplService.HideAlertPath, 'get');
    if (params) {
      rb.header('alertNotificationId', params.alertNotificationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `hideAlert$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hideAlert(params: {
    alertNotificationId: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.hideAlert$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation hasRecentAlertsForNaSensorByDeviceResponse
   */
  static readonly HasRecentAlertsForNaSensorByDeviceResponsePath = '/alerts/has-recent-alerts-for-na-sensor-by-device';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hasRecentAlertsForNaSensorByDeviceResponse()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasRecentAlertsForNaSensorByDeviceResponse$Response(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<HasRecentAlertsForNaSensorByDeviceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AlertManagementImplService.HasRecentAlertsForNaSensorByDeviceResponsePath, 'get');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HasRecentAlertsForNaSensorByDeviceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `hasRecentAlertsForNaSensorByDeviceResponse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasRecentAlertsForNaSensorByDeviceResponse(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<HasRecentAlertsForNaSensorByDeviceResponse> {

    return this.hasRecentAlertsForNaSensorByDeviceResponse$Response(params).pipe(
      map((r: StrictHttpResponse<HasRecentAlertsForNaSensorByDeviceResponse>) => r.body as HasRecentAlertsForNaSensorByDeviceResponse)
    );
  }

  /**
   * Path part for operation getUserAlertsByDevice
   */
  static readonly GetUserAlertsByDevicePath = '/alerts/by-device';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserAlertsByDevice()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAlertsByDevice$Response(params: {
    'X-Authenticated-User': string;
    pageable: Pageable;
    deviceId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PageAlertNotificationDto>> {

    const rb = new RequestBuilder(this.rootUrl, AlertManagementImplService.GetUserAlertsByDevicePath, 'get');
    if (params) {
      rb.header('X-Authenticated-User', params['X-Authenticated-User'], {});
      rb.query('pageable', params.pageable, {});
      rb.query('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageAlertNotificationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserAlertsByDevice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAlertsByDevice(params: {
    'X-Authenticated-User': string;
    pageable: Pageable;
    deviceId: string;
    context?: HttpContext
  }
): Observable<PageAlertNotificationDto> {

    return this.getUserAlertsByDevice$Response(params).pipe(
      map((r: StrictHttpResponse<PageAlertNotificationDto>) => r.body as PageAlertNotificationDto)
    );
  }

  /**
   * Path part for operation getUserAlertsByDeviceAndDataType
   */
  static readonly GetUserAlertsByDeviceAndDataTypePath = '/alerts/by-device-and-data-type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserAlertsByDeviceAndDataType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAlertsByDeviceAndDataType$Response(params: {
    'X-Authenticated-User': string;
    pageable: Pageable;
    deviceId: string;
    dataType: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PageAlertNotificationDto>> {

    const rb = new RequestBuilder(this.rootUrl, AlertManagementImplService.GetUserAlertsByDeviceAndDataTypePath, 'get');
    if (params) {
      rb.header('X-Authenticated-User', params['X-Authenticated-User'], {});
      rb.query('pageable', params.pageable, {});
      rb.query('deviceId', params.deviceId, {});
      rb.query('dataType', params.dataType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageAlertNotificationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserAlertsByDeviceAndDataType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAlertsByDeviceAndDataType(params: {
    'X-Authenticated-User': string;
    pageable: Pageable;
    deviceId: string;
    dataType: string;
    context?: HttpContext
  }
): Observable<PageAlertNotificationDto> {

    return this.getUserAlertsByDeviceAndDataType$Response(params).pipe(
      map((r: StrictHttpResponse<PageAlertNotificationDto>) => r.body as PageAlertNotificationDto)
    );
  }

  /**
   * Path part for operation getAlertsForNaSensorByDevice
   */
  static readonly GetAlertsForNaSensorByDevicePath = '/alerts/alerts-for-na-sensor-by-device';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAlertsForNaSensorByDevice()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAlertsForNaSensorByDevice$Response(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GetAlertsForNaSensorByDeviceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AlertManagementImplService.GetAlertsForNaSensorByDevicePath, 'get');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetAlertsForNaSensorByDeviceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAlertsForNaSensorByDevice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAlertsForNaSensorByDevice(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<GetAlertsForNaSensorByDeviceResponse> {

    return this.getAlertsForNaSensorByDevice$Response(params).pipe(
      map((r: StrictHttpResponse<GetAlertsForNaSensorByDeviceResponse>) => r.body as GetAlertsForNaSensorByDeviceResponse)
    );
  }

  /**
   * Path part for operation getAlertsForImpedanceSensorByDevice
   */
  static readonly GetAlertsForImpedanceSensorByDevicePath = '/alerts/alerts-for-impedance-sensor-by-device';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAlertsForImpedanceSensorByDevice()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAlertsForImpedanceSensorByDevice$Response(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GetAlertsForImpedanceSensorByDeviceResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AlertManagementImplService.GetAlertsForImpedanceSensorByDevicePath, 'get');
    if (params) {
      rb.query('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetAlertsForImpedanceSensorByDeviceResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAlertsForImpedanceSensorByDevice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAlertsForImpedanceSensorByDevice(params: {
    deviceId: string;
    context?: HttpContext
  }
): Observable<GetAlertsForImpedanceSensorByDeviceResponse> {

    return this.getAlertsForImpedanceSensorByDevice$Response(params).pipe(
      map((r: StrictHttpResponse<GetAlertsForImpedanceSensorByDeviceResponse>) => r.body as GetAlertsForImpedanceSensorByDeviceResponse)
    );
  }

  /**
   * Path part for operation getUserAlerts
   */
  static readonly GetUserAlertsPath = '/alerts/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserAlerts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAlerts$Response(params: {
    'X-Authenticated-User': string;
    pageable: Pageable;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PageAlertNotificationDto>> {

    const rb = new RequestBuilder(this.rootUrl, AlertManagementImplService.GetUserAlertsPath, 'get');
    if (params) {
      rb.header('X-Authenticated-User', params['X-Authenticated-User'], {});
      rb.query('pageable', params.pageable, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageAlertNotificationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserAlerts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAlerts(params: {
    'X-Authenticated-User': string;
    pageable: Pageable;
    context?: HttpContext
  }
): Observable<PageAlertNotificationDto> {

    return this.getUserAlerts$Response(params).pipe(
      map((r: StrictHttpResponse<PageAlertNotificationDto>) => r.body as PageAlertNotificationDto)
    );
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AlertManagementImplService
} from "../../../../generated/hydroponics-device-management-api/services/alert-management-impl.service";
import {LoadAlertsAndWorksheetsNumberService} from "../../../service/load-alerts-and-worksheets-number.service";
import {
  DeviceManagementImplService
} from "../../../../generated/hydroponics-device-management-api/services/device-management-impl.service";
import {DeviceDto} from "../../../../generated/hydroponics-device-management-api/models/device-dto";

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-alert-table',
  templateUrl: './alert-table.component.html',
  styleUrls: ['./alert-table.component.sass']
})
export class AlertTableComponent implements OnInit {

  @Input() deviceId: string;
  @Input() dataType: string;
  @Input() visible!: boolean;
  @Output() closeTable = new EventEmitter<void>();
  @Output() onHideAlert = new EventEmitter<number>();

  alerts!: any[];
  pageSizeOptions: number[] = [5, 10, 15, 30, 50, 100];
  isLoading: boolean;
  totalRecords = 0;
  rows = 5;
  first = 0;
  currentPage = 0;

  constructor(private readonly alertManagementImplService: AlertManagementImplService,
              private readonly deviceManagementImplService: DeviceManagementImplService,
              private readonly loadAlertsAndWorksheetsNumberService: LoadAlertsAndWorksheetsNumberService
  ) {
  }

  filterTypes: any[] = [];
  filterType: { label: string, value: DeviceDto };

  ngOnInit(): void {
    this.getAllDevices();
    this.getAlerts();
  }

  private getAlerts() {
    this.isLoading = true;
    if (this.deviceId != undefined && this.deviceId != null) {
      this.alertManagementImplService.getUserAlertsByDeviceAndDataType({
        "X-Authenticated-User": null,
        pageable: {page: this.currentPage, size: this.rows},
        deviceId: this.deviceId,
        dataType: this.dataType
      }).subscribe(value => {
        this.alerts = value.content.map(alert => ({
          ...alert,
          selected: false,
        }));
        this.totalRecords = value.totalElements;
        this.isLoading = false;
      });
    } else {
      this.alertManagementImplService.getUserAlerts({
        "X-Authenticated-User": null,
        pageable: {page: this.currentPage, size: this.rows}
      }).subscribe(value => {
        this.alerts = value.content.map(alert => ({
          ...alert,
          selected: false,
        }));
        this.totalRecords = value.totalElements;
        this.isLoading = false;
      });
    }
  }

  private getAllDevices() {
    this.deviceManagementImplService.getAllDevice().subscribe(value => {
      value.forEach(device => this.filterTypes.push({label: device.name, value: device}))
    })
  }

  actionOnClose() {
    this.closeTable.emit();
  }

  // the logic of the function is not final
  // hideAlert(alertNotificationId: number, dataType: string) {
  //   this.alertManagementImplService.hideAlert({alertNotificationId}).subscribe(value => {
  //     this.onHideAlert.emit(alertNotificationId);
  //     this.getAlerts();
  //     this.loadAlertsNumberService.(alertNotificationId, dataType);
  //   });
  // }

  selectAllCheckboxes() {
    const allSelected = this.alerts.every(alert => alert.selected);

    if (allSelected) {
      this.alerts.forEach(alert => alert.selected = false);
    } else {
      this.alerts.forEach(alert => alert.selected = true);
    }

    this.alerts.filter(alert => alert.selected);
  }

  pageChanged(event: PageEvent) {
    this.rows = event.rows;
    this.first = event.first;
    this.currentPage = event.first / event.rows;

    this.getAlerts();
  }

  onCheckboxChange() {
    this.alerts.filter(alert => alert.selected);
  }

  closeAlert() {
    const selectedAlerts = this.alerts.filter(alert => alert.selected);

    if (selectedAlerts.length > 0) {
      this.isLoading = true;

      const selectedAlertIds = selectedAlerts.map(alert => alert.id);
      console.log(selectedAlertIds)

      this.alertManagementImplService.closeAlerts({body: selectedAlertIds}).subscribe(value => {
        this.onHideAlert.emit();
        this.getAlerts();
        this.loadAlertsAndWorksheetsNumberService.emitEvent();
      })
    }
  }

  onChangeDevice() {
    if (this.filterType && this.filterType.value != undefined && this.filterType.value != null) {
      this.alertManagementImplService.getUserAlertsByDevice({
        "X-Authenticated-User": null,
        deviceId: this.filterType.value.deviceId,
        pageable: {page: this.currentPage, size: this.rows}
      }).subscribe(value => {
        this.alerts = value.content.map(alert => ({
          ...alert,
          selected: false,
        }));
        this.totalRecords = value.totalElements;
        this.isLoading = false;
      });
    }
  }

  onDeviceCleared(){
    this.alertManagementImplService.getUserAlerts({
      "X-Authenticated-User": null,
      pageable: {page: this.currentPage, size: this.rows}
    }).subscribe(value => {
      this.alerts = value.content.map(alert => ({
        ...alert,
        selected: false,
      }));
      this.totalRecords = value.totalElements;
      this.isLoading = false;
    });
  }
}
